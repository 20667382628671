<template>
  <Layout>
    <div class="container-study-creation">
      <BreadCrumb
        :items="[
          { name: 'Home', path: '/' },
          { name: 'Cronograma de estudos', path: '/cronograma/introducao' },
          { name: 'Criação', path: '#' },
        ]"
      />
      <h1>Vamos começar organizando seus estudos!</h1>

      <b-form class="creation-form-group" @submit="onSubmit">
        <b-form-group id="exam-form">
          <label for="exam-radio-input"
            >Quais vestibulares você vai prestar?</label
          >
          <b-form-radio-group
            id="exam-radio-input"
            v-model="exam"
            :options="examOptions"
            value-field="value"
            text-field="text"
            required
          >
          </b-form-radio-group>
        </b-form-group>

        <b-form-group id="course-form">
          <label for="course-input">Qual curso você quer passar?</label>
          <b-form-input
            id="course-input"
            v-model="course"
            placeholder="Curso que você quer passar"
            v-bind:required="courseNotdefined === false"
          ></b-form-input>

          <b-form-checkbox
            id="course_checkbox"
            v-model="courseNotdefined"
            :value="true"
            :unchecked-value="false"
          >
            Ainda não decidi o curso
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          id="university-form"
          label="Em qual instituição você quer estudar?"
          label-for="university-input"
        >
          <b-form-input
            id="university-input"
            v-model="university"
            placeholder="Universidade/faculdade que você quer passar"
            v-bind:required="universityNotDecided === false"
          ></b-form-input>

          <b-form-checkbox
            id="university_checkbox"
            v-model="universityNotDecided"
            :value="true"
            :unchecked-value="false"
          >
            Ainda não decidi a universidade/faculdade
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          id="study-hours-form"
          label="Quantas horas por semana que você pretende estudar?"
          label-for="study-hours-radio"
        >
          <b-form-radio-group
            id="study-hours-radio"
            v-model="hoursPerWeek"
            :options="studyHoursOptions"
            value-field="value"
            text-field="text"
            required
          >
          </b-form-radio-group>

          <div class="group-description">
            Equivale a média de {{ hoursPerWeek / 5 }}H de estudos por dia.
          </div>
        </b-form-group>

        <b-form-group
          id="language-form"
          label="Qual língua estrangeira você pretende realizar na prova?"
          label-for="language-radio"
        >
          <b-form-radio-group
            id="language-radio"
            v-model="language"
            :options="languageOptions"
            value-field="value"
            text-field="text"
            required
          >
          </b-form-radio-group>
        </b-form-group>

        <b-form-group
          id="basic-math-form"
          label="Quer incluir Matemática Básica no seu plano de estudos?"
          label-for="basic-math-radio"
        >
          <b-form-radio-group
            name="basic-math-radio"
            v-model="includeBasicMath"
            :options="basicMathOptions"
            value-field="value"
            text-field="text"
            required
          >
          </b-form-radio-group>
        </b-form-group>

        <ButtonPlay type="normal" submit>
          <div class="icon-button">Gerar meu plano de estudos</div>
        </ButtonPlay>
      </b-form>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "StudyScheduleCreation",
  components: {
    Layout: () => import("@/layout/Layout.vue"),
    ButtonPlay: () => import("@/components/common/ButtonPlay.vue"),
    BreadCrumb: () => import("@/components/common/Breadcrumb.vue"),
  },
  data() {
    return {
      exam: "enem",
      examOptions: [
        { text: "ENEM", value: "enem" },
        { text: "ENEM e Outros Vestibulares", value: "enem_and_others" },
      ],
      course: "",
      courseNotdefined: false,
      university: "",
      universityNotDecided: false,
      hoursPerWeek: 10,
      studyHoursOptions: [
        { text: "10h", value: 10 },
        { text: "20h", value: 20 },
        { text: "30h", value: 30 },
        { text: "40h", value: 40 },
      ],
      language: "en",
      languageOptions: [
        { text: "Inglês", value: "en" },
        { text: "Espanhol", value: "es" },
      ],
      includeBasicMath: true,
      basicMathOptions: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getLanguage: "studySchedule/getLanguage",
      getHoursPerWeek: "studySchedule/getHoursPerWeek",
      getIncludeBasicMath: "studySchedule/getIncludeBasicMath",
      getStartDate: "studySchedule/getStartDate",
    }),
  },
  methods: {
    ...mapActions({
      actionSetIsLoading: "config/actionSetIsLoading",
      actionCreateSettings: "studySchedule/actionCreateSettings",
      actionFetchSettings: "studySchedule/actionFetchSettings",
    }),

    async onSubmit(evt) {
      evt.preventDefault();

      this.actionSetIsLoading(true);
      await this.actionCreateSettings({
        // exam: this.exam,
        // course: this.course,
        // university: this.university,

        startDate: new Date().toISOString().split("T")[0],
        hoursPerWeek: this.hoursPerWeek,
        language: this.language,
        includeBasicMath: this.includeBasicMath,
      })
        .then(() => {
          this.actionSetIsLoading(false);
          this.$router.push({
            name: "study_schedule",
          });
        })
        .catch((error) => {
          console.log("Erro ao criar configurações");
          console.log(error);
          this.actionSetIsLoading(false);
        })
        .finally(() => {
          this.actionSetIsLoading(false);
        });
    },

    loadSavedSettings() {
      console.log("getIncludeBasicMath", this.getIncludeBasicMath);
      if (this.getHoursPerWeek != null) {
        this.hoursPerWeek = this.getHoursPerWeek;
      }
      if (this.getLanguage != null) {
        this.language = this.getLanguage;
      }
      if (this.getIncludeBasicMath != null) {
        this.includeBasicMath = this.getIncludeBasicMath;
      }
    },
  },
  async created() {
    await this.actionFetchSettings();
    this.loadSavedSettings();
  },
};
</script>

<style lang="scss" scoped>
.container-study-creation {
  padding-top: 1.5rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  padding-bottom: 8.31rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: var(--fontcolor);
    margin-top: 1.4rem;
    margin-bottom: 2rem;
  }

  label {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--fontcolor);
  }
}
/* Substitui os estilos do Bootstrap Vue */
::v-deep .custom-radio .custom-control-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--fontcolor);
  margin-top: 0.5rem;
}

::v-deep .custom-checkbox .custom-control-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--fontcolor);
  margin-top: 0.5rem;
}

::v-deep .custom-control-input:checked ~ .custom-control-label {
  color: var(--maincolor);
}

.creation-form-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 27rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.form-control {
  box-shadow: var(--bordercolor) 0px 0px 0px 0.04rem;
  border-radius: 8px;
}

.form-control:focus {
  box-shadow: var(--maincolor) 0px 0px 0px 0.04rem;
}

.group-description {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--fontcolor2);
  margin-top: 0.5rem;
}

.icon-button {
  @include flex-row;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;

  ::v-deep .svgCss {
    svg {
      width: 1rem;
      height: 1rem;
    }

    svg path {
      fill: #fff !important;
    }
  }
}
</style>
